<template>
    <div>
        <br>
        <v-spacer/>
        <br>
    </div>
</template>
<script>
    export default {
        name: 'CardSpacer'
    }
</script>
